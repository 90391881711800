import React from "react";
import styles from "./styles.css";
import SaleBannerImage from "./images/sale-banner.webp";
import SearchRevamp from "../search-revamp";
import { Helmet } from "react-helmet";

const SaleBanner = () => {
    return (
        <React.Fragment>
            <Helmet>
                <link rel="preload" href={SaleBannerImage} as={"image"} type="image/jpg" />
            </Helmet>
            <div styleName={"styles.wrapper"}>
                <img src={SaleBannerImage} alt="Black Friday sale banner" />
                <div styleName={"styles.contentWrapper"}>
                    <div className="container">
                        <p styleName={"styles.heading"}>BLACK FRIDAY SALE</p>
                        <h1 styleName={"styles.bannerText"}>SAVE UP TO $7,000</h1>
                        <h2 styleName={"styles.dates"}>22nd NOV - 1st DEC</h2>
                        <div styleName={"styles.outer"}>
                            <SearchRevamp isHomePage={true} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default SaleBanner;
