import React from "react";
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import Banner from "./component";
import {fetchFilters, updateSSRStatus as updateFilterSSRStatus, clearAllFilters,
    updateSelectedOnScreenFilter, updateFilterOptionData, updateAllSubFilterOptions, updateAppliedFilters, setListingFBEvents} from "../filters/actions";
import { toggleLocationPicker } from "../location-picker-popup/actions";
import { reloadCarList, resetCarList } from "./actions";
import { setShowAllowTracking } from "../../au.configuration/actions";
import { fetchConfig, sendAppLink } from "../home/actions";
import { fetchBookedTasksList } from "../booked-tasks/actions";
import {  getPreApprovalLoanDetails } from "../pre-approval-financing-flow/pre-approval-loan-wrapper/actions";

const mapStateToProps = ({
    filters: {
        showAllFilters,
        isSSR: isFilterSSR,
        allFilters,
        selectedOnScreenFilter
    },
    cities: {
        selectedCity
    },
    carListing: {
        shouldReloadList,
        metaContent
    },
    user: {
        showAllowTracking,
        secureToken,
        isLoggedIn
    },
    config: {
        saleConfig
    },
    preApprovalLoan: {
        preApproved
    },
    bookedTasksList: {bookedTasks},
    myBookings: {
        bookings
    }

}) => ({
    showAllFilters,
    isFilterSSR,
    selectedCity,
    shouldReloadList,
    metaContent,
    makeFilterData: allFilters[selectedOnScreenFilter],
    showAllowTracking,
    secureToken,
    saleConfig,
    preApproved,
    isLoggedIn,
    bookedTasks,
    allFilters,
    bookings
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFiltersConnect: fetchFilters,
    updateFilterSSRStatusConnect: updateFilterSSRStatus,
    clearAllFiltersConnect: clearAllFilters,
    toggleLocationPickerConnect: toggleLocationPicker,
    updateSelectedOnScreenFilterConnect: updateSelectedOnScreenFilter,
    reloadCarListConnect: reloadCarList,
    updateFilterOptionDataConnect: updateFilterOptionData,
    updateAllSubFilterOptionsConnect: updateAllSubFilterOptions,
    resetCarListConnect: resetCarList,
    updateAppliedFiltersConnect: updateAppliedFilters,
    setShowAllowTrackingConnect: setShowAllowTracking,
    fetchConfigConnect: fetchConfig,
    sendAppLinkConnect: sendAppLink,
    fetchBookedTasksListConnect: fetchBookedTasksList,
    setListingFBEventsConnect: setListingFBEvents,
    getPreApprovalLoanDetailsConnect: getPreApprovalLoanDetails

}, dispatch);

export default React.memo(withRouter(connect(mapStateToProps, mapDispatchToProps)(Banner)));
