import React from "react";
import styles from "./styles.css";
import SearchRevamp from "../search-revamp";
import BANNERIMG from "./images/banner2.png";

const LandingBannerSellRevamp = () => {

    return (
        <div styleName="styles.wrapper">
            <img styleName={"styles.imgWraaper"} src={BANNERIMG} />
            <div className="container">
                <div styleName={"styles.bgOuter"}>
                    <p styleName={"styles.text"}>Love it or return it in 7 days. 100% money<br /> back guarantee.</p>
                </div>
                <SearchRevamp isHomePage={true} />
            </div>
        </div>

    );
};

LandingBannerSellRevamp.propTypes = {};

export default LandingBannerSellRevamp;
