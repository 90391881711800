import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getMyBookings } from "../my-bookings/actions";
import { getOrderDetail, getVerificationStatus, setSplitPayment } from "../checkout-info/actions";
import { getFinancePostPaymentApplication } from "../finance-post-payment-loan-application/actions";
import { fetchBookedTasksList } from "./actions";
import BookedTasks from "./component";

const mapStateToProps = ({
    user: {secureToken, isUserZeroDpVariant},
    bookedTasksList: {bookedTasks},
    financePostPayment: {
        loanApplication,
        isGetLoanApplicationLoading,
        isGetLoanApplicationError,
        applicationFormConfigData,
        documentUploadConfigData,
        timeoutDetails
    },
    checkout: {
        verifyIdCompleted,
        order
    },
    myBookings: {
        bookings,
        isFetching
    },
    preApprovalLoan: {
        stEligible
    }
}) => ({
    secureToken,
    bookedTasks,
    loanApplication,
    isGetLoanApplicationLoading,
    isGetLoanApplicationError,
    applicationFormConfigData,
    documentUploadConfigData,
    verifyIdCompleted,
    order,
    isUserZeroDpVariant,
    timeoutDetails,
    bookings,
    isFetching,
    stEligible
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchBookedTasksListConnect: fetchBookedTasksList,
    getFinancePostPaymentApplicationConnect: getFinancePostPaymentApplication,
    getVerificationStatusConnect: getVerificationStatus,
    setSplitPaymentConnect: setSplitPayment,
    getOrderDetailConnect: getOrderDetail,
    getMyBookingsConnect: getMyBookings
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BookedTasks);
